import { IMAGE_FRAGMENT, IImage } from './image'
import { OPTION_TYPE_FRAGMENT, IOptionType } from './option-type'
import { RELATED_PRODUCT_FRAGMENT, IRelatedProduct } from './related-product'
import { SUBSCRIPTION_FRAGMENT, ISubscription } from './subscription'
import { VARIANT_FRAGMENT, IVariant, SLIM_VARIANT_FRAGMENT } from './variant'
import { IProductCategory } from './product-category'

export const MAIN_IMAGE_SLUG_FRAGMENT = `
  slug
  mainImage {
    ${IMAGE_FRAGMENT}
  }
`

export const SLIM_PRODUCT_FRAGMENT = `
  sys {
    id
  }
  __typename
  bastaId
  isActive
  name
  slug
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  variantsCollection(limit: 15) {
    total
    items {
      ${SLIM_VARIANT_FRAGMENT}
    }
  }
  categoriesCollection {
    items {
      name
    }
  }
`

export const PRODUCT_FRAGMENT = `
  sys {
    id
  }
  __typename
  bastaId
  isActive
  name
  slug
  shortDescription
  longDescription
  ingredients
  directions
  mainImage {
    ${IMAGE_FRAGMENT}
  }
  optionTypesCollection(limit: 3) {
    items {
      ${OPTION_TYPE_FRAGMENT}
    }
  }
  variantsCollection(limit: 15) {
    total
    items {
      ${VARIANT_FRAGMENT}
    }
  }
  subscription {
    ${SUBSCRIPTION_FRAGMENT}
  }
  relatedProductsCollection(limit: 9) {
    items {
     ${RELATED_PRODUCT_FRAGMENT}
    }
  }
  imagesCollection(limit: 5) {
    items {
      ${IMAGE_FRAGMENT}
    }
  }
`

export interface IProduct {
  sys: {
    id: string
  }
  __typename: 'Product'
  bastaId: string | null
  isActive?: boolean | null
  name: string
  slug: string
  shortDescription: string | null
  longDescription: string | null
  ingredients: string | null
  directions: string | null
  mainImage: IImage
  optionTypesCollection?: {
    items: IOptionType[]
  }
  variantsCollection?: {
    total: number
    items: IVariant[]
  }
  subscription?: ISubscription | null
  relatedProductsCollection?: {
    items: IRelatedProduct[]
  }
  imagesCollection: {
    items: IImage[]
  }
  categoriesCollection: {
    items: IProductCategory[]
  }
}

export interface IProductAsset {
  slug: string
  mainImage: IImage
}
