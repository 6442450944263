import { fetchGraphQL } from 'graphql-cms/api'
import {
  ARCHIVE_FRAGMENT,
  IArchive,
  SLIM_ARCHIVE_FRAGMENT,
} from 'graphql-cms/fragments/archive'
import { IProduct } from 'graphql-cms/types'

interface Query {
  archiveCollection: {
    items: IArchive[] | null
  }
}

export const getArchives = async (): Promise<IArchive[]> => {
  const query = `
    query {
      archiveCollection(where: { slug_exists: true }) {
        items {
          ${SLIM_ARCHIVE_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  if (!response.data.archiveCollection.items) {
    return []
  }

  return response.data.archiveCollection.items
}

export const getCollectionPageProductCategories = async (): Promise<
  string[]
> => {
  const query = `
    query {
      archiveCollection(where: { featured: true }, limit: 1) {
        items {
           dropsListCollection(limit: 10) {
            items {
               categoriesCollection {
                items {
                  name
                }
              }
            }
          }
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  if (!response.data.archiveCollection.items) {
    return [] as string[]
  }

  const filters =
    response.data.archiveCollection.items[0].dropsListCollection.items.map(
      (item) => {
        return item.categoriesCollection.items.map((item) => item.name)
      }
    )

  return [...new Set(filters.flat())]
}

export const getFeaturedArchiveProducts = async (): Promise<IProduct[]> => {
  const query = `
    query {
      archiveCollection(where: { featured: true }, limit: 1) {
        items {
          ${ARCHIVE_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  if (!response.data.archiveCollection.items) {
    return {} as IProduct[]
  }

  return response.data.archiveCollection.items[0].dropsListCollection.items
}

export const getFeaturedArchive = async (): Promise<IArchive> => {
  const query = `
    query {
      archiveCollection(where: { featured: true }) {
        items {
          ${SLIM_ARCHIVE_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  if (!response.data.archiveCollection.items) {
    return {} as IArchive
  }

  return response.data.archiveCollection.items[0]
}

export const getAllArchivesSlugs = async (): Promise<string[]> => {
  const query = `
    query {
      archiveCollection(where: { slug_exists: true }) {
        items {
          slug
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  if (!response.data.archiveCollection.items) {
    return []
  }

  return response.data.archiveCollection.items.map(({ slug }) => slug)
}

export const getSlimArchive = async ({
  slug,
}: {
  slug: string
}): Promise<IArchive> => {
  const query = `
    query GetArchive($slug: String!) {
      archiveCollection(where: {slug: $slug }, limit: 1)  {
        items {
          ${SLIM_ARCHIVE_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query, { slug })

  if (!response.data.archiveCollection.items?.[0]) {
    throw new Error(`No Archive found for slug: ${slug}`)
  }

  return response.data.archiveCollection.items[0]
}

export const getArchive = async ({
  slug,
}: {
  slug: string
}): Promise<IArchive> => {
  const query = `
    query GetArchive($slug: String!) {
      archiveCollection(where: {slug: $slug }, limit: 1)  {
        items {
          ${ARCHIVE_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query, { slug })

  if (!response.data.archiveCollection.items?.[0]) {
    throw new Error(`No Archive found for slug: ${slug}`)
  }

  return response.data.archiveCollection.items[0]
}
