/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Flex } from 'theme-ui'
import NavMenuLogo from './Logo'
import NavMenuLogoBlack from './LogoBlack'
import NavMenuLinks from './Links'
import NavMenuLink from './Link'
import NavMenuControls from './Controls'
import NavMenuToggle from './Toggle'
import MainLines from '~/assets/images/logos/mainLines.svg'
import MainLinesBlack from '~/assets/images/logos/mainLinesBlack.svg'
import { IMenu, IProductAsset } from 'graphql-cms/types'
import theme from '~/theme'
import DrawerCart from '../DrawerCart'

const NavMenu: React.FC<
  IMenu & {
    hasHero: boolean
    productsAssets: IProductAsset[]
    featuredCelebrityName: string
  }
> = (menuConfig) => {
  const [isOpen, setIsOpen] = useState(false)
  const { hasHero, productsAssets, headerLinksCollection } = menuConfig
  const pages = headerLinksCollection?.items || []
  const menuRef = useRef(null)
  const router = useRouter()
  const handleRouteChange = () => {
    setIsOpen(false)
  }
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)
  }, [menuRef])

  const showCartDrawer =
    router.pathname !== '/checkout' &&
    router.pathname !== '/order/confirmation/[number]/[id]'

  return (
    <Flex
      sx={{
        alignItems: 'center',
        color: `${theme.colors.white}`,
        fontFamily: 'Nanum Gothic Bold',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        display: ['flex', 'flex', 'none'],
        justifyContent: 'space-between',
        padding: ['0 10px', '0 10px', '0'],
        width: '100%',
      }}
    >
      <Flex
        sx={{ alignItems: 'center', marginTop: '18px', marginBottom: '18px' }}
      >
        {hasHero && <NavMenuLogo />}
        {!hasHero && <NavMenuLogoBlack />}
      </Flex>
      <NavMenuLinks isOpen={isOpen} setIsOpen={setIsOpen}>
        {pages.map((page, index) => (
          <NavMenuLink
            sx={{
              paddingBottom: '35px',
              color: `${theme.colors.black}`,
              fontFamily: 'Nanum Gothic',
              fontSize: '15px',
              fontStyle: 'normal',
              lineHeight: 'normal',
            }}
            page={page}
            key={page.slug}
            index={index}
            featuredCelebrityName={menuConfig.featuredCelebrityName}
          />
        ))}
      </NavMenuLinks>
      <NavMenuControls>
        {showCartDrawer && (
          <DrawerCart hasHero={hasHero} productsAssets={productsAssets} />
        )}
        <NavMenuToggle
          sx={{
            flexShrink: 0,
            '& svg': { width: '21px', height: '7px' },
            marginRight: '0px',
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {hasHero && <MainLines />}
          {!hasHero && <MainLinesBlack />}
        </NavMenuToggle>
      </NavMenuControls>
    </Flex>
  )
}

export default NavMenu
